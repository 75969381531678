:root {
  --gray-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  --mask-bottom-scroll-gradient: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 15%
  );
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
body,
input,
textarea,
select,
button {
  font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}
body {
  height: 100%;
  background: #f7f7f7;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  overflow-x: hidden;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: auto;
}

input {
  font-size: 1.4rem;
}
